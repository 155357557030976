import ChartArrows from "@/ui/components/icons/ChartArrows";
import ChartHistogram from "@/ui/components/icons/ChartHistogram";
import Dollar from "@/ui/components/icons/Dollar";
import Gavel from "@/ui/components/icons/Gavel";
import GitFork from "@/ui/components/icons/GitFork";
import Presentation from "@/ui/components/icons/Presentation";
import Robot from "@/ui/components/icons/Robot";
import SettingsGear from "@/ui/components/icons/SettingsGear";
import UsersIcon from "@/ui/components/icons/Users";
import { SocietyView } from "@mappers";
import { useSession } from "next-auth/react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SocietyNavData = (societyContext: SocietyView) => {
  const { data: sessionData } = useSession();

  const list = [
    // TODO: Hidden for now, but should either fully remove this or make it "useful" by pulling real stats
    // {
    //   title: "Overview",
    //   link: "/overview",
    //   icon: <CircleSquare size={28} />,
    // },
    {
      title: "Profiles",
      link: "/profiles",
      icon: <UsersIcon height={28} width={28} />,
    },
    {
      title: "Groups",
      link: "groups",
      icon: <Gavel size={28} />,
      children: [
        { title: "All", link: "" },
        { title: "Members", link: "members" },
      ],
    },
    {
      title: "Accounting",
      link: "accounting",
      icon: <Dollar size={28} />,
      children: [
        { title: "Invoices", link: "invoices" },
        { title: "Invoice Items", link: "invoices/items" },
        { title: "Payments", link: "payments" },
        { title: "Payment Items", link: "payments/items" },
        { title: "Promo Codes", link: "promo-codes" },
        { title: "Deferred Dues", link: "deferred-dues" },
      ],
    },
    {
      title: "Chatbot",
      link: "/chatbot",
      icon: <Robot size={28} />,
    },
    {
      title: "Settings",
      link: "/settings",
      icon: <SettingsGear size={28} />,
    },
  ];
  if (sessionData?.societyAdmin?.superAdmin) {
    list.push({
      title: "Conferences",
      link: "/conferences",
      icon: <Presentation size={28} />,
    });
    list.push({
      title: "Chapters",
      link: "/chapters",
      icon: <GitFork size={28} />,
    });
    list.push({
      title: "Workflows",
      link: "/workflows",
      icon: <ChartArrows size={28} />,
    });
    list.push({
      title: "Workflow Builder",
      link: "/workflow-builder",
      icon: <ChartHistogram size={28} />,
    });
  }
  return list;
};

export default SocietyNavData;
