import EllipsisDots from "@/ui/components/icons/EllipsisDots";
import SocietySearchList from "@/ui/components/layout/side-nav/SocietySelector/societySearchList";
import { Dialog, Transition } from "@headlessui/react";
import { AdminSocietyView } from "@mappers";

import { ComponentPropsWithoutRef, Fragment, useEffect, useState } from "react";

type SocietySelectorProps = ComponentPropsWithoutRef<"div"> & {
  societies: AdminSocietyView[];
};

const SocietySelector = ({ societies, ...props }: SocietySelectorProps) => {
  const [open, setOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [resultCount, setResultCount] = useState(0);

  const calculateCount = () => {
    return societies.filter((socieity: AdminSocietyView) => {
      return socieity.abbreviation
        .toLowerCase()
        .includes(searchString.toLowerCase());
    }).length;
  };

  useEffect(() => {
    setResultCount(calculateCount());
  }, [searchString]);

  useEffect(() => {
    setResultCount(() => {
      return societies.filter((society: AdminSocietyView) => society.isPinned)
        .length;
    });
    // timeout need for the animation of closing the modal
    setTimeout(() => {
      setShowAll(false);
      setSearchString("");
    }, 250);
  }, [open]);

  return (
    <>
      <div
        {...props}
        onClick={() => alert("This component is still under construction")}
      >
        <EllipsisDots size={28} />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="top-[224px] w-[320px] fixed inset-0 z-10  ">
            <div className=" wat flex min-h-full items-end ml-20 p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="top-20 h-80 relative shadow-lg transform rounded-lg bg-neutral-light  text-left border-2 transition-all w-full    sm:p-4  ">
                  <div>
                    <div className="mt-1.5 sm:mt-5 ">
                      <Dialog.Title
                        as="h2"
                        className="font-medium lead text-xl text-neutral-mid-800"
                      >
                        Societies
                      </Dialog.Title>
                      <div className="mt-1">
                        <div className="relative ">
                          <div className="flex absolute left-1 bottom-4 items-center   pointer-events-none">
                            <svg
                              aria-hidden="true"
                              className="w-[17px] h-[17px] text-neutral-mid-500"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                              ></path>
                            </svg>
                          </div>
                          <input
                            className="indent-6 border border-slate-400 mb-3 rounded-md text-neutral-mid-500 h-[25px]"
                            autoComplete="off"
                            type="text"
                            placeholder="Search"
                            id="username"
                            value={searchString || ""}
                            onClick={() => {
                              setResultCount(calculateCount());
                              setShowAll(true);
                            }}
                            onChange={(e) => setSearchString(e.target.value)}
                            title="Search All Societies"
                          />
                        </div>
                        {societies && (
                          <div className="">
                            <div className="flex text-neutral-mid-500">
                              {!showAll && <h6>PINNED</h6>}
                              {showAll && <h6>RESULTS</h6>}
                              <h6 className="ml-auto bg-neutral-mid-200 px-2 rounded-md">
                                {resultCount}
                              </h6>
                            </div>
                            <hr className="my-1.5" />
                            <SocietySearchList
                              closeModal={() => {
                                setOpen(false);
                              }}
                              societies={societies
                                .sort(
                                  (
                                    a: AdminSocietyView,
                                    b: AdminSocietyView,
                                  ) => {
                                    return a.name.localeCompare(b.name);
                                  },
                                )
                                .filter((society: AdminSocietyView) => {
                                  return showAll ? true : society.isPinned;
                                })}
                              searchString={searchString}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SocietySelector;
