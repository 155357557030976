import { ComponentPropsWithoutRef } from "react";

//TODO: We should remove size in order to favor passed in values for the svg component props (height, width)
// Leaving this for now to support legacy code.
type ChevronUpProps = ComponentPropsWithoutRef<"svg"> & {
  size?: number;
};

const ChevronUp = ({ size, ...props }: ChevronUpProps) => {
  const iconSize = size ? size : "44";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-chevron-down"
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <polyline points="6 15 12 9 18 15"></polyline>
    </svg>
  );
};

export default ChevronUp;
