import ChevronRight from "@/ui/components/icons/ChevronRight";
import Pin from "@/ui/components/icons/pin/Pin";
import Pinned from "@/ui/components/icons/pin/Pinned";
import PinnedOff from "@/ui/components/icons/pin/PinnedOff";
// TODO: STYLING replace or remove
// import societyBorders from "@/ui/oldstyles/themes/society-color-constants/society-borders";
import { AdminSocietyView } from "@mappers";

import Link from "next/link";
import router from "next/router";
import { useState } from "react";

const SocietyRow = ({
  society,
  searchString,
  closeModal,
}: {
  society: AdminSocietyView;
  searchString: string;
  closeModal: () => void;
}) => {
  const [rowHover, setRowHover] = useState(false);

  const unPinnSociety = () => {
    console.log("unpin socieity");
  };
  const pinnSociety = () => {
    console.log("pin socieity");
  };

  return (
    <>
      {society.abbreviation
        .toLowerCase()
        .includes(searchString.toLowerCase()) && (
        <div
          onMouseEnter={() => {
            setRowHover(true);
          }}
          onMouseLeave={() => {
            setRowHover(false);
          }}
          className="h-[46px] mr-[14px] w-full flex hover:rounded-md hover:bg-neutral-mid-200"
        >
          <div
            onClick={() => {
              router.push(`/society/${society.id}/profiles`);
              closeModal();
            }}
            className="flex flex-row hover:cursor-pointer"
          >
            <div
              // className={societyBorders(society.theme.color)}
              style={{
                background: `url("${society.logo}")`,
                width: "36px",
                height: "36px",
                backgroundSize: "cover",
                margin: "5px",
              }}
            />
            <div className="pt-1">
              <h4>{society.abbreviation}</h4>
            </div>
          </div>
          <div className="ml-auto flex">
            <div className="hover:cursor-pointer">
              {!rowHover ? (
                <div>
                  {society.isPinned && (
                    <div className="pr-4 pt-3">
                      <Pinned size={20} />
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex">
                  {society.isPinned && (
                    <div className="pt-1.5" onClick={() => pinnSociety()}>
                      <PinnedOff size={20} />
                    </div>
                  )}
                  {!society.isPinned && (
                    <div className="pt-1.5" onClick={() => unPinnSociety()}>
                      <Pin size={20} />
                    </div>
                  )}
                  <div className="pt-1.5">
                    <Link
                      onClick={() => {
                        closeModal();
                      }}
                      href={`/society/${society.id}/profiles`}
                    >
                      <div className="text-neutral-mid-500 mb-4 ml-2">
                        <ChevronRight size={12} />
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SocietyRow;
