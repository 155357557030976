import { ComponentPropsWithoutRef } from "react";

//TODO: We should remove size and just passthrough height/width.
type ChevronRightProps = ComponentPropsWithoutRef<"svg"> & {
  size?: number;
};

const ChevronRight = ({ size, ...props }: ChevronRightProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-chevron-right"
      width={size ?? 24}
      height={size ?? 24}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <polyline points="9 6 15 12 9 18"></polyline>
    </svg>
  );
};

export default ChevronRight;
