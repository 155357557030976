type Props = {
  size?: number;
};

const Presentation = ({ size }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-presentation-analytics "
      width={size ?? 28}
      height={size ?? 28}
      viewBox="0 0 24 24"
      strokeWidth="1.75"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M9 12v-4"></path>
      <path d="M15 12v-2"></path>
      <path d="M12 12v-1"></path>
      <path d="M3 4h18"></path>
      <path d="M4 4v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-10"></path>
      <path d="M12 16v4"></path>
      <path d="M9 20h6"></path>
    </svg>
  );
};

export default Presentation;
