import { useIsFetching } from "@tanstack/react-query";
import Link from "next/link";
import { ComponentPropsWithoutRef } from "react";
import Image from "next/image";
import { useSession } from "next-auth/react";

type PowerBarProps = ComponentPropsWithoutRef<"nav">;

const PowerBar = (props: PowerBarProps) => {
  const isFetching = useIsFetching();
  const session = useSession();
  return (
    <nav
      {...props}
      className={`${
        isFetching
          ? "powerbar-container-animated"
          : "powerbar-container-general"
      } ${props.className ?? ""}
      `}
    >
      <div className="flex h-full w-full items-center">
        <Link href="/" className="link-undecorated">
          <Image
            src="/logos/PillarLogo2023.png"
            alt="Pillar Logo"
            layout="responsive"
            height={250}
            width={100}
            className="flex h-full w-full"
          />
        </Link>
        {session.data?.environment !== "prod" && (
          <div className="flex h-full w-full items-center justify-left">
            <span className="text-red-500 text-xl font-bold">
              {session.data?.environment}
            </span>
          </div>
        )}
      </div>
    </nav>
  );
};

export default PowerBar;
