import Link from "next/link";
import { ComponentPropsWithoutRef } from "react";

type AppLinkProps = Omit<ComponentPropsWithoutRef<typeof Link>, "href"> & {
  path: string;
  href?: string;
  children: React.ReactNode;
};
const AppLink = ({ path, href, children, ...props }: AppLinkProps) => {
  //TODO: This should be removed, but leaving for now so as not to break legacy code.
  //Should just use href.
  const truePath = path ?? href;
  return (
    <Link href={truePath} {...props}>
      {children}
    </Link>
  );
};
export default AppLink;
