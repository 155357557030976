import AppLink from "@/ui/components/common/AppLink";
import ChevronDown from "@/ui/components/icons/ChevronDown";
import ChevronUp from "@/ui/components/icons/ChevronUp";
import { useSession } from "next-auth/react";
// TODO: STYLING replace or remove
// import societyBorders from "@/ui/oldstyles/themes/society-color-constants/society-borders";
import { Disclosure } from "@headlessui/react";

import SocietyNavData from "@/constants/layout/SocietyNavData";

import { ComponentPropsWithoutRef } from "react";
import DoubleChevronLeft from "../../icons/DoubleChevronLeft";

type SocietyNavProps = ComponentPropsWithoutRef<"div"> & {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
};

const SocietyNav = ({
  isOpen,
  setOpen,
  className,
  ...props
}: SocietyNavProps) => {
  const { data } = useSession();

  return (
    <>
      {
        <div
          {...props}
          // TODO: Duration and animation do not appear to be working
          className={`container-sidebar sidebar-menu-container transition duration-200 flex flex-col ${className}`}
        >
          <div className="h-full flex flex-col pl-1 pr-1">
            <div className="h-full flex flex-col">
              <div className="flex justify-start items-center w-full">
                <div
                  className={`society-image w-8 h-8 ${isOpen ? "mr-1" : ""}`}
                  style={{
                    background: `url("${data?.society?.logo}")`,
                    backgroundSize: "cover",
                  }}
                />
                {isOpen && <h2>{data?.society?.abbreviation}</h2>}
              </div>
              <div className="mt-1 mb-3 flex flex-col items-center pt-3">
                {data?.society &&
                  SocietyNavData(data.society).map((item) => (
                    <div
                      key={item.title}
                      className={`sidebar-menu-selector flex group mb-1 pt-1.5 pb-1.5 ${
                        isOpen
                          ? "w-25 pl-3 pr-3 justify-start"
                          : "w-8 justify-center"
                      }`}
                    >
                      {!item.children ? (
                        <AppLink
                          className="link-undecorated"
                          path={`/society/${data?.societyId}${item.link}`}
                        >
                          <div className="w-full flex">
                            <div
                              className={`sidebar-menu-svg h-3.5 w-3.5 ${
                                isOpen ? "mr-1" : ""
                              }`}
                            >
                              {item.icon}
                            </div>
                            {isOpen && (
                              <h4 className="sidebar-menu-title">
                                {item.title}
                              </h4>
                            )}
                          </div>
                        </AppLink>
                      ) : (
                        <Disclosure key={item.title}>
                          {({ open }) => (
                            <div className="flex flex-col">
                              <Disclosure.Button
                                onClick={() => {
                                  setOpen(true);
                                }}
                              >
                                <div className="w-full flex">
                                  <div className="sidebar-menu-svg h-3.5 w-3.5 mr-1">
                                    {item.icon}
                                  </div>
                                  {isOpen && (
                                    <h4 className="sidebar-menu-title">
                                      {item.title}
                                    </h4>
                                  )}
                                  {isOpen &&
                                    (open ? (
                                      <ChevronUp className="sidebar-menu-svg w-2 h-2 mt-1 ml-1" />
                                    ) : (
                                      <ChevronDown className="sidebar-menu-svg w-2 h-2 mt-1 ml-1" />
                                    ))}
                                </div>
                              </Disclosure.Button>
                              {isOpen && (
                                <Disclosure.Panel>
                                  <div className="flex">
                                    <div className="sidebar-menu-submenu-vert w-[1px]" />
                                    <div className="flex flex-col">
                                      {item.children.map((subItem) => (
                                        <AppLink
                                          key={`${item.title}-${subItem.title}`}
                                          className="link-undecorated"
                                          path={`/society/${data?.societyId}/${item.link}/${subItem.link}`}
                                        >
                                          <h5 className="sidebar-menu-submenu-title pl-1">
                                            {subItem.title}
                                          </h5>
                                        </AppLink>
                                      ))}
                                    </div>
                                  </div>
                                </Disclosure.Panel>
                              )}
                            </div>
                          )}
                        </Disclosure>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            {/* In most cases, we should not use arbitrary values in our Tailwind utility classes.
            In this case, it is hyper specific to this context and only this context and not
            something we will ever want to theme, so it is fine in this niche case. */}
            <DoubleChevronLeft
              className={`sidebar-menu-selector-svg self-start ${
                !isOpen ? "-scale-x-[1] self-end" : "self-start"
              }`}
              onClick={() => {
                setOpen(!isOpen);
              }}
            />
          </div>
        </div>
      }
    </>
  );
};

export default SocietyNav;
