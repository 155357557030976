import { AdminSocietyView } from "@mappers";
import SocietyRow from "./societyRow";

type Props = {
  closeModal: () => void;
  searchString: string;
  societies: AdminSocietyView[];
};

const SocietySearchList = ({ closeModal, searchString, societies }: Props) => {
  return (
    <div className="flex flex-wrap flex-row overflow-y-auto h-[164px] w-[220px]">
      {societies.map((society: AdminSocietyView) => {
        return (
          <SocietyRow
            key={society.abbreviation}
            closeModal={closeModal}
            society={society}
            searchString={searchString}
          />
        );
      })}
    </div>
  );
};

export default SocietySearchList;
