import { StepSelection } from "@/ui/types/workflow/workflow";

export const Status = {
  In_Progress: "In Progress",
  Current: "Current",
  Draft: "Draft",
} as const;

export const ErrorMessage = {
  Bad_Request: "Bad Request",
  Unauthorized: "Unauthorized",
  Missing_Form_ID: "Missing Form ID",
  Missing_Body_Object: "The body object was not supply",
  Method_Not_Allowed: "Request Method Not Allowed",
  Missing_Society_Id: "Society ID was not provided.",
  Unable_To_Create_Object:
    "The server was unable to create a new object with the given input",
  Missing_or_Invalid_Query_Parameters: "Missing or Invalid Query Parameters",
  Internal_Server_Error: "Internal Server Error",
  Invalid_Request_Body: "Invalid Request Body",
} as const;

export enum Steps {
  OPEN = "Open",
  REVIEW = "Review",
  DECISION = "Decision",
  NOTIFICATION = "Notification",
  TASK = "Task",
}

export const WorkflowStepSelection: StepSelection[] = [
  { order: 2, name: Steps.REVIEW, isSelected: false, forms: [] },
  { order: 3, name: Steps.DECISION, isSelected: false, forms: [] },
  { order: 4, name: Steps.NOTIFICATION, isSelected: false, forms: [] },
  { order: 5, name: Steps.TASK, isSelected: false, forms: [] },
];

export const SubmissionStatus = {
  Withdrawn: "Withdrawn",
  In_Progress: Status.In_Progress,
  Complete: "Complete",
  Review_Recused: "Review Recused",
  Review_Complete: "Review Complete",
  Review_In_Progress: "Review In Progress",
} as const;

export const SessionStorageKey = {
  SOCIETY_ID: "SocietyId",
} as const;
export const SurveyJSViewMode = {
  Edit: "edit",
  Read_Only: "display",
} as const;

export const PrismaErrorCode = {
  Unique_constraint_failed: "P2002",
} as const;
