import { ComponentPropsWithoutRef } from "react";

const DoubleChevronLeft = (props: ComponentPropsWithoutRef<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 24 24"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <polyline points="11 7 6 12 11 17"></polyline>
      <polyline points="17 7 12 12 17 17"></polyline>
    </svg>
  );
};

export default DoubleChevronLeft;
