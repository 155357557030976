import { SocietyTheme } from "@/server/mappers/society/theme";

const hslToHex = (theme: SocietyTheme[]) => {
  let h = Number(
    theme?.find((item) => item.property === "color-society-hue")?.value,
  );
  let s = Number(
    theme
      ?.find((item) => item.property === "color-society-saturation")
      ?.value.slice(0, -1),
  );
  let l = Number(
    theme
      ?.find((item) => item.property === "color-society-lightness")
      ?.value.slice(0, -1),
  );

  h /= 360;
  s /= 100;
  l /= 100;

  let r = 0,
    g = 0,
    b = 0;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  const toHex = (c: number) => {
    const hex = Math.round(c * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const hexValue = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

  return hexValue.toUpperCase();
};

export { hslToHex };
