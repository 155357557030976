import { ProfileView } from "@mappers";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import router from "next/router";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { getProfileByID } from "../api-callouts/profiles";
import Spinner from "../components/common/Spinner";

export interface ProfileContextType extends ProfileView {
  refreshProfile: () => Promise<void>;
}

const ProfileContext = createContext<ProfileContextType | null>(null);

type Props = {
  children: ReactNode;
};

const ProfileContextProvider = ({ children }: Props) => {
  const queryClient = useQueryClient();
  const [profile, setProfile] = useState<ProfileView | null>(null);

  const profileDetails = useQuery(
    ["profileDetails", router.query.profileId],
    async () => await getProfileByID(Number(router.query.profileId)),
    {
      enabled: !!router.query.profileId,
      onSuccess: (data) => {
        setProfile(data);
      },
    },
  );
  const refreshProfile = async () => {
    await queryClient.invalidateQueries([
      "profileDetails",
      router.query.profileId,
    ]);
    setProfile(profileDetails.data!);
  };

  useEffect(() => {
    queryClient.invalidateQueries();
  }, []);

  useEffect(() => {
    if (profileDetails.data) {
      setProfile(profileDetails.data);
    } else {
      setProfile(null);
    }
  }, [router.query.profileId, profileDetails.isLoading]);

  const profileValue = useMemo(
    () => ({ ...profile!, refreshProfile }),
    [profile, refreshProfile],
  );
  if (profileValue && !profileDetails.isLoading && profileValue.id) {
    return (
      <ProfileContext.Provider value={profileValue}>
        {children}
      </ProfileContext.Provider>
    );
  }
  if (router.query.profileId) {
    return <Spinner />;
  }
  return <>{children}</>;
};

const useProfileContext = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error("useProfileContext must be used within a ProfileContext");
  }
  if (context === null) {
    throw new Error("ProfileContext is null");
  }
  return context;
};

export { ProfileContext, ProfileContextProvider, useProfileContext };
