import { ReactNode, createContext, useContext, useState } from "react";

const BreadCrumbContext = createContext<BreadCrumbContextType | null>(null);

export type BreadCrumb = {
  display: string;
  route: string;
};

export type BreadCrumbContextType = {
  crumbList: BreadCrumb[];
  setBreadCrumbs: (breadCrumb: BreadCrumb[]) => void;
  pushNewCrumb: (breadCrumb: BreadCrumb) => void;
};

type Props = {
  children: ReactNode;
};

const BreadCrumbContextProvider = ({ children }: Props) => {
  const [crumbList, setCrumbList] = useState<BreadCrumb[]>([]);

  const setBreadCrumbs = (breadCrumbs: BreadCrumb[]) => {
    setCrumbList([...breadCrumbs]);
  };

  const pushNewCrumb = (breadCrumb: BreadCrumb) => {
    // Check if breadCrumb is already in crumbList
    const isInList = crumbList.some(
      (crumb) => crumb.display === breadCrumb.display,
    );

    if (!isInList) {
      setCrumbList([...crumbList, breadCrumb]);
    }
  };
  const context: BreadCrumbContextType = {
    pushNewCrumb,
    crumbList,
    setBreadCrumbs,
  };

  return (
    <BreadCrumbContext.Provider value={context}>
      {children}
    </BreadCrumbContext.Provider>
  );
};

const useBreadCrumbContext = (): BreadCrumbContextType => {
  const context = useContext(BreadCrumbContext);
  if (context === undefined) {
    throw new Error(
      "useBreadCrumbContext must be used within a BreadCrumbContext",
    );
  }
  if (context === null) {
    throw new Error("BreadCrumbContext is null");
  }
  return context;
};

export { BreadCrumbContext, BreadCrumbContextProvider, useBreadCrumbContext };
