import { useBreadCrumbContext } from "@/ui/context/breadCrumbContext";
import router from "next/router";
import React from "react";

const BreadCrumb = () => {
  const breadCrumbContext = useBreadCrumbContext();
  return (
    <nav className="container">
      <ol className="list-reset pb-1 rounded flex bg-neutral-mid-light text-neutral-mid">
        {breadCrumbContext.crumbList.map((item, index) => {
          return (
            <React.Fragment key={`billCrumb-${index}-fragment`}>
              <li key={`billCrumb-${index}-slash`}>/</li>
              <li
                className="px-1 hover:cursor-pointer"
                key={`billCrumb-${index}-text`}
              >
                <div
                  key={`billCrumb-${index}-text-div`}
                  onClick={() => {
                    breadCrumbContext.setBreadCrumbs(
                      breadCrumbContext.crumbList.slice(0, index + 1),
                    );
                    router.push(item.route);
                  }}
                >
                  <p key={`billCrumb-${index}-text-paragraph`}>
                    {item.display}
                  </p>
                </div>
              </li>
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
