import SocietySelector from "@/ui/components/layout/side-nav/SocietySelector";
// TODO: STYLING replace or remove
// import societyBorders from "@/ui/oldstyles/themes/society-color-constants/society-borders";

import Link from "next/link";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";

import { hslToHex } from "@/utils/helpers/color-helpers";
import { useSession } from "next-auth/react";
import { CSSProperties } from "styled-components";
import Building from "../../icons/Building";
import CheckList from "../../icons/CheckList";
import UsersIcon from "../../icons/Users";

type SideBarProps = ComponentPropsWithoutRef<"div">;

const SideBar = ({ className, ...props }: SideBarProps) => {
  const [showModal, setShowModal] = useState(false);
  const { data: sessionData } = useSession();
  useEffect(() => {
    setShowModal(true);
  }, [showModal]);
  return (
    <div
      className={`container-sidebar flex flex-col items-center justify-between ${
        className ?? ""
      }`}
      {...props}
    >
      <div className="flex flex-col items-center">
        {sessionData?.societyAdmin?.superAdmin && (
          <div className="sidebar-menu-selector group">
            <Link href="/admin" className="sidebar-menu-svg">
              <UsersIcon className="h-4.5 w-4.5" />
            </Link>
          </div>
        )}
        {sessionData?.societyAdmin && (
          <div className="sidebar-menu-selector group">
            <Link
              href="/task-management/kanban-board"
              className="link-undecorated sidebar-selector-svg mt-2"
              id="task-management-icon-link"
            >
              <CheckList className="h-4.5 w-4.5" />
            </Link>
          </div>
        )}
        {sessionData?.societyAdmin?.superAdmin && (
          <div className="sidebar-menu-selector group">
            <Link
              href="/venues"
              className="link-undecorated sidebar-menu-svg mt-2"
            >
              <Building className="h-4.5 w-4.5" />
            </Link>
          </div>
        )}
      </div>

      <div className="flex flex-col items-center">
        {sessionData?.societyAdmin?.societies.sort().map((society) => {
          //Since these styles are built dynamically, they are not a candidate for the Tailwind
          //utility classes. Tailwind recommends doing dynamic styling in this way to ensure
          //the style elements are created properly on-the-fly.
          let societyStyles: CSSProperties & { "--tw-shadow-color"?: string } =
            {
              background: `url("${society.logo}")`,
              backgroundSize: "cover",
            };
          if (society?.theme) {
            societyStyles = {
              ...societyStyles,
              borderColor: hslToHex(society.theme),
              "--tw-shadow-color": hslToHex(society.theme),
            };
          }
          return (
            society.isPinned && (
              <Link
                title={society.abbreviation}
                key={society.id}
                href={`/society/${society.id}/profiles`}
              >
                <div
                  className={`sidebar-selector-image h-4.5 w-4.5 mt-2 ${
                    hslToHex(society.theme!)
                      ? `hover:shadow-[${hslToHex(
                          society.theme!,
                        )}] border-[${hslToHex(society.theme!)}]`
                      : ""
                  }`}
                  style={societyStyles}
                />
              </Link>
            )
          );
        })}
        {sessionData?.societyAdmin?.societies && (
          <SocietySelector
            className="sidebar-selector-svg mt-2"
            societies={sessionData.societyAdmin?.societies}
          />
        )}
      </div>
    </div>
  );
};

export default SideBar;
