import ApiRequest from "@/ui/api-callouts/api-request-builder";
import { PaginationRequest } from "@/ui/types/common/general-types";
import { HttpMethod } from "@/ui/types/common/http";
import { PaginatedResults, ProfileLiteView, ProfileView } from "@mappers";

import { SessionStorageKey } from "@/constants";
import { FiltersRequest } from "@/utils/helpers/filter-where-clause";
import { getSessionStorageItem } from "@/utils/session-storage";

export const getProfileByID = async (id: number): Promise<ProfileView> => {
  const response = await ApiRequest(
    `/api/v1/society/${getSessionStorageItem(
      SessionStorageKey.SOCIETY_ID,
    )}/profiles/${id}`,
    HttpMethod.GET,
  );
  return response?.body;
};

export const getProfiles = async (
  filters: FiltersRequest,
  pagination?: PaginationRequest,
): Promise<PaginatedResults<ProfileLiteView>> => {
  const response = await ApiRequest(
    `/api/v1/society/${getSessionStorageItem(
      SessionStorageKey.SOCIETY_ID,
    )}/profiles`,
    HttpMethod.GET,
    undefined,
    undefined,
    undefined,
    filters,
    pagination,
  );
  return response?.body;
};

export const getProfileFilterDDLItems = async () => {
  const response = await ApiRequest(
    `/api/v1/society/${getSessionStorageItem(
      SessionStorageKey.SOCIETY_ID,
    )}/filters/profile-filter-ddl`,
    HttpMethod.GET,
  );
  return response?.body;
};

export const deleteProfile = async (profile_id: number) => {
  const response = await ApiRequest(
    `/api/v1/society/${getSessionStorageItem(
      SessionStorageKey.SOCIETY_ID,
    )}/profiles/${profile_id}/delete`,
    HttpMethod.DELETE,
    profile_id,
  );
  return response?.body;
};
