import { ComponentPropsWithoutRef } from "react";

//TODO: We should remove size in order to favor passed in values for the svg component props (height, width)
// Leaving this for now to support legacy code.
type ChevronDownProps = ComponentPropsWithoutRef<"svg"> & {
  size?: number;
};

const ChevronDown = ({ size, ...props }: ChevronDownProps) => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-chevron-down"
        width={size ?? 32}
        height={size ?? 32}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <polyline points="6 9 12 15 18 9" />
      </svg>
    </span>
  );
};

export default ChevronDown;
