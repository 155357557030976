// TODO: STYLING replace or remove
// import { BaseTheme } from "@/ui/oldstyles/constants/Colors";
// import { ThemeService } from "@/ui/oldstyles/themes/theme";
import { SessionStorageKey } from "@/constants";

import { SocietyTheme } from "@/server/mappers/society/theme";
import { setSessionStorageItem } from "@/utils/session-storage";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Spinner from "../common/Spinner";

type Props = {
  children: JSX.Element | JSX.Element[];
};
export const setSocietyTheme = (theme: SocietyTheme[]) => {
  theme.forEach((theme) => {
    document.documentElement.style.setProperty(
      `--${theme.property}`,
      theme.value,
    );
  });
};

const Helmet = ({ children }: Props) => {
  const router = useRouter();
  const { data: session, status, update: updateSession } = useSession();

  useEffect(() => {
    const societyId = router.query.id ? Number(router.query.id) : undefined;
    if (societyId && societyId !== session?.societyId) {
      updateSession({
        societyId: societyId,
      });
    }

    const society = session?.societyAdmin?.societies.find(
      (s) => societyId && s.id === societyId,
    );

    if (society && society.theme) {
      setSocietyTheme(society.theme);
    }
  }, [
    session?.societyAdmin?.societies,
    router.query.id,
    session?.society?.theme,
  ]);

  useEffect(() => {
    if (status === "unauthenticated" && router.isReady) {
      router.replace("api/auth/signin");
    }
  }, [router, status]);

  if (
    !router.isReady ||
    status === "loading" ||
    (router.query.id && Number(router.query.id) !== session?.societyId)
  ) {
    return (
      <>
        <Spinner />
      </>
    );
  }
  if (router.query.id) {
    setSessionStorageItem(
      SessionStorageKey.SOCIETY_ID,
      router.query.id.toString(),
    );
  }
  if (status === "authenticated") {
    return <>{children}</>;
  }
  return <></>;
};

export default Helmet;
