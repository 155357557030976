import Helmet from "@/ui/components/auth/Helmet";
import PowerBar from "@/ui/components/layout/PowerBar";
import SideBar from "@/ui/components/layout/side-nav/SideBar";
import SocietyNav from "@/ui/components/layout/side-nav/SocietyNav";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import "@styles/index.css";
import { setSessionStorageItem } from "@/utils/session-storage";
import { SessionStorageKey } from "@/constants";
import BreadCrumb from "@/ui/components/layout/BreadCrumb";
import { BreadCrumbContextProvider } from "@/ui/context/breadCrumbContext";
import { ProfileContextProvider } from "@/ui/context/profileContext";

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [isOpen, setOpen] = useState(true);

  const router = useRouter();
  const { id } = router.query;
  const societyName = id?.toString();
  // const [societyDisplayName, setSocietyDisplayName] = useState("");
  const [societyView, setSocietyView] = useState(false);
  useEffect(() => {
    if (societyName === undefined) {
      setSessionStorageItem(SessionStorageKey.SOCIETY_ID, "");
      setSocietyView(false);
    } else {
      // We know society has to exist at this point because if it doesn't we wouldn't have a societyName either.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setSessionStorageItem(SessionStorageKey.SOCIETY_ID, id!.toString());
      setSocietyView(true);
      // setSocietyDisplayName(societyName);
    }
  }, [societyName, router]);
  // const [isClient, setIsClient] = useState(false);

  // useEffect(() => {
  //   setIsClient(true);
  // }, []);
  // if (!isClient) {
  //   //TODO: This stops all serverside rendering which is the point of NextJs but otherwise we get a hydration issue Gary bets its on tailwind.
  //   return <></>;
  // }
  return (
    <>
      <Head>
        <title>PMG Admin</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
      </Head>
      <SessionProvider refetchOnWindowFocus={false}>
        <QueryClientProvider client={queryClient}>
          <div className="w-full h-full flex flex-col overflow-hidden">
            <Helmet>
              <BreadCrumbContextProvider>
                <PowerBar className="h-10 flex-none" />
                <div className="flex flex-1 overflow-hidden">
                  {isOpen && (
                    <SideBar className="w-10 pb-3 flex-none overflow-y-auto" />
                  )}
                  {societyView && (
                    <SocietyNav
                      className="pb-3 overflow-y-auto flex-shrink-0"
                      isOpen={isOpen}
                      setOpen={setOpen}
                    />
                  )}
                  <div className="container-secondary overflow-auto flex-grow">
                    <BreadCrumb />
                    <ProfileContextProvider>
                      <Component {...pageProps} />
                    </ProfileContextProvider>
                  </div>
                </div>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
              </BreadCrumbContextProvider>
            </Helmet>
          </div>
        </QueryClientProvider>
      </SessionProvider>
    </>
  );
};

export default MyApp;
